import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate,useParams } from 'react-router-dom';
import { FaCalendarCheck } from "react-icons/fa";
import { IoAlertCircle } from "react-icons/io5";
import emailjs from "@emailjs/browser";
import { show_alerta } from "../Utils/functions"
import Navbar from '../Components/Navbar/Navbar';
import Footer from '../Components/Footer/Footer';

function ConfirmationScreen() {
    const idTransaction = localStorage.getItem('idTransaccion');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [datos, setDatos] = useState({})
    const [reservationData,setReservationData] = useState({})

    const returnToHome = () =>{
        navigate("/")
    } 

    const changeState = () =>{
        if (idTransaction === null){
          show_alerta("No se encontro el id", "warning")
          return
        }
        // Llamada a la API para confirmar el estado con el ID de transacción
        axios.get(`https://nativo503sv.com/getReservationAuth.php?id=${idTransaction}`)
          .then(response => {
            // Manejar la respuesta de la API según sea necesario
            console.log(response.data);
            setDatos(response.data)
            getTripinfo()
          })
          .catch(error => {
            console.error(error);
          });
    }

    const getTripinfo = () =>{
      // Llamada a la API para confirmar el estado con el ID de transacción
      axios.get(`https://nativo503-api.onrender.com/api/getOneReservation/${idTransaction}`)
        .then(response => {
          // Manejar la respuesta de la API según sea necesario
          console.log(response.data);
          setReservationData(response.data)
          sendingEmails(response.data)
        })
        .catch(error => {
          console.error(error);
        });
  }

  useEffect(() => {
   changeState()
   setup()
  }, []);

  const setup =() =>{
    emailjs.init("f2q_iROJ-VeakYRWT");
  }

  const sendingEmails =(data) => {

    sendEmail(data, generateEmailTemplateClient(data))
    sendEmailProvider(generateEmailTemplateProvider(data))
  }

  const generateEmailTemplateClient = (data) => {
    const saludo = "¡Hola! Gracias por confiar en Nativo503";
    const despedida = "¡Que tengas un excelente día!";
    
    const emailTemplate = `
      ${saludo}

      ${data.tripId.destTitle}
      Viajero:        ${data.fullName}
      Participantes:  ${data.customersQty} Adultos (Edad 12-99)
      Fecha:          ${data.reservationDate}
      Idioma:         ${data.preferedLanguage}
      Direccion:      ${data.pickupAddress}
      Total:          $${data.total}
      
      Nuestro numero de Contacto: +50378873305
      
      ${despedida}
    `;
    
    return emailTemplate;
  };
  
  const generateEmailTemplateProvider = (data) => {
    const saludo = "¡Hola! Han reservado el siguiente trip";
    const despedida = "¡Que tengas un excelente día!";
    
    const emailTemplate = `
      ${saludo}

      ${data.tripId.destTitle}
      Trip:           ${data.tripId.destTitle}
      Viajero:        ${data.fullName}
      Participantes:  ${data.customersQty} Adultos (Edad 12-99)
      Fecha:          ${data.reservationDate}
      Idioma:         ${data.preferedLanguage}
      Telefono:       ${data.phoneNumber}
      Direccion:      ${data.pickupAddress}
      Pasaporte:      ${data.idNumber}
      Nacionalidad:   ${data.originNation}
      Total:          $${data.total}
      Autorizacion:   ${datos.autorizacion}
      Estado:         ${datos.codigo == "00" ? "Autorizado" : "Denegado"}
      
      Nuestro numero de Contacto: +50378873305
      
      ${despedida}
    `;
    
    return emailTemplate;
  }; 

  const sendEmail = async (data, msj) =>{
    //setLoading(true);
    const serviceId = "service_rdexzsm";
    const templateId = "template_yrrxzlv";
    try {
      await emailjs.send(serviceId, templateId, {
       to_name: data.fullname,
       recipient: data.email,
       mensaje: msj
      });
      //setLoading(false);
      alert(t("mailSuccess"),"success");
    } catch (error) {
      console.log(error);
      show_alerta(error, "error")
      //setLoading(false);
    } finally {
      //clearValues();
    }
  }

  const sendEmailProvider = async (msj) =>{
    //setLoading(true);
    const serviceId = "service_rdexzsm";
    const templateId = "template_yrrxzlv";
    try {
      await emailjs.send(serviceId, templateId, {
       to_name: "Nativo503",
       recipient: "nativo503sv@gmail.com",
       mensaje: msj
      });
      //setLoading(false);
      alert(t("mailSuccess"),"success");
    } catch (error) {
      console.log(error);
      show_alerta(error, "error")
      //setLoading(false);
    } finally {
      //clearValues();
    }
  }

  return (
    <>
    <Navbar />
    <div className='container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
      <h1>{t("confirmationTitle")}</h1>
      {/* Contenido de la pantalla de confirmación */}
      {datos.codigo == '00' ? <FaCalendarCheck style={{ height: '20vh' , width: '20vh', color: "#08A045"}}/> : <IoAlertCircle style={{ height: '20vh', width: '20vh', color: "#AB3428" }}/>}
        <h3>{t("Reservation")} {datos.codigo == '00' ? t("Exitosa") : t("Rechazada")} </h3> <br/><br/>
        <button onClick={returnToHome} className='btn'>{t("GoToHome")}</button>
    </div>
    </>
  );
}

export default ConfirmationScreen;
