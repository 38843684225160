import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { show_alerta } from "../../Utils/functions"
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReactStars from 'react-stars'
import { IoPersonCircleSharp } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

import './comments.css'
function Comments({tripData}) {

  const baseUrl ='https://nativo503-api.onrender.com/api'
  const navigate = useNavigate();

  const [name, setName] = useState("")
  const [commentary, setCommentary] = useState("")
  const [rating, setRating] = useState(0)
  const [averageRating, setAverageRating] = useState(0)

  const [reviews, setReviews] = useState([])

  const { t } = useTranslation();

  const ratingChanged = (newRating) => {
    console.log(newRating)
    setRating(newRating)
  }
  const returnToHome = () => {
    navigate('/')
    console.log("Pasaste al detalle")
  }

  //MARK: - Metodo para traer la lista de los comentarios
  const getData = async () => {
    await axios.get(baseUrl+'/getComments/'+tripData._id)
    .then(res => {
       //console.log(res.data)
       setAverageRating(res.data.averageRating)
       setReviews(res.data.reviews);
      })
      .catch(error => {
        console.log(error)
    })
}

const clearInputFields = () => {
  document.getElementById('name').value = '';
  document.getElementById('commentary').value = '';
}



  const handleCommentary = async() =>{
    //Add authorization Number..
      if(name == null || commentary == null || rating == 0){
        show_alerta(t("validationFields"), "error")
      }else{

      const parametros = {
        name: name,
        commentary: commentary,
        stars: rating    
      }

      var header ={'Content-Type': 'application/json'}

      await axios({method: 'PUT',url: baseUrl+'/updateCommentary/'+tripData._id,headers: header, data: parametros})
            .then(function(response){
              //console.log(response.data)
              show_alerta(t("alertComment"), 'success')
              setName("");
              setCommentary("");
              setRating(0);
              clearInputFields();
              getData();
            })
            .catch(function(error){
              show_alerta(t("alertCommentError"),'error')
              clearInputFields();
              console.log(error)
            })
      }

  }

  useEffect(() => {
      getData()
    }, []);

  return (
    <div className='singleDestination container'>
    <h1>{t("reviewsTitle")}</h1>
    <div data-aos='fade-up' className="cardDiv grid">
          
          <div className="destinationInput">
              <label htmlFor='name'>{t("labelName")}</label>
              <div className="input flex">
                <input type="text" name="name" id='name' placeholder={t("placeholderName")} onChange={(e) => setName(e.target.value)} required />
              </div>
            </div>

            <div className="commentary">
              <label htmlFor='commentary'>{t("labelComment")}</label>
              <div className="input flex">
                <input type="text" name='commentary' id='commentary' placeholder={t("placeholderComment")} onChange={(e) => setCommentary(e.target.value)} required/>
              </div>
            </div>

            <div className='stars'>
            <label htmlFor='commentary'>{t("rate")}</label>
              <div className=' flex'>
              <ReactStars count={5} value={rating} onChange={ratingChanged} size={24} color2={'#ffd700'} />
              </div>
            </div>

            <button className='btn' onClick={handleCommentary}>
              {t("btnSend")}
            </button>
    </div>
    
      {reviews?.map((review) => {
        return(
          <div className='cardDiv' key={review._id} data-aos='fade-up'>
            
            <h3><IoPersonCircleSharp className='personIcon' />{review.name}</h3> 
            <ReactStars value={review.stars}/>
            <p>{review.commentary}</p>
            </div>
        
        )
      })}
 
    
    </div>
  )
}

export default Comments