import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import MainRentAcar from '../Components/RentACarComp/MainRentAcar/MainRentAcar'

function RentACar() {
  return (
    <>
    <Navbar/>
    <MainRentAcar />
    <Footer/>
    </>
  )
}

export default RentACar