import React, {useEffect, useState} from 'react'
import './home.css'
import axios from 'axios';
import imagen from '../../Assets/Eramon1.JPG'
import { GrLocation } from "react-icons/gr";
import emailjs from "@emailjs/browser";
import { FaInstagram } from "react-icons/fa6";
import { RiBillFill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { show_alerta } from "../../Utils/functions"
import Swal from "sweetalert2";

import Aos from 'aos';
import 'aos/dist/aos.css'

function Home() {
  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false)

  const [datos, setDatos] = useState({})
  const [reservationData,setReservationData] = useState({})

  useEffect(()=>{
    setup()
  },[])

  const initializeAos = () =>{
    Aos.init({duration: 2000})
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const setup =() =>{
    emailjs.init("f2q_iROJ-VeakYRWT");
    initializeAos();
    setupLoading()
    validateIfComesFromPayment() // Valida Si viene de un pago
  }

  const setupLoading = () =>{
    if (loading) {
      Swal.fire({
          title: 'Cargando...',
          allowOutsideClick: false,
          onBeforeOpen: () => {
              Swal.showLoading();
          }
      });
  } else {
      Swal.close();
  }
  }

  const validateIfComesFromPayment = () =>{
    const idTransaction = localStorage.getItem('idTransaccion');
    const transaccion = localStorage.getItem('transaccion');
    if (idTransaction != null && transaccion != null && transaccion == "trip"){
      //alert("Vienes de pagar.")
      //console.log("Cuantas veces repite esto")
      getTripinfo(idTransaction)
    }else if(idTransaction != null && transaccion != null && transaccion == "rentaCar"){
      getRentacarinfo(idTransaction)
    }
  }


const getTripinfo = (idTransaction) =>{
  // Llamada a la API para confirmar el estado con el ID de transacción
  axios.get(`https://nativo503-api.onrender.com/api/getOneReservation/${idTransaction}`)
    .then(response => {
      // Manejar la respuesta de la API según sea necesario
      //console.log(response.data);
      setReservationData(response.data)
      showMessage(response.data)
      sendingEmails(response.data)
    })
    .catch(error => {
      console.error(error);
    });
}

const getRentacarinfo = (idTransaction) =>{
  // Llamada a la API para confirmar el estado con el ID de transacción
  axios.get(`https://nativo503-api.onrender.com/api/getOneCarReservation/${idTransaction}`)
    .then(response => {
      // Manejar la respuesta de la API según sea necesario
      //console.log(response.data);
      setReservationData(response.data)
      showMessage(response.data)
      sendingEmailsCars(response.data)
    })
    .catch(error => {
      console.error(error);
    });
}

const showMessage = (data) =>{
  if(data.isPayed == "00"){
    show_alerta(t("Reservation")+" "+ t("Exitosa"), "success")
  }else{
    show_alerta(t("Reservation")+" "+ t("Rechazada"), "error")
  }
  
}

const sendingEmails =(data) => {

  sendEmailClient(data, generateEmailTemplateClient(data))
  sendEmail(generateEmailTemplateProvider(data))

  localStorage.removeItem('idTransaccion')
  localStorage.removeItem('transaccion')
}

const sendingEmailsCars =(data) => {

  sendEmailClient(data, generateEmailTemplateClientCar(data))
  sendEmail(generateEmailTemplateProviderCar(data))

  localStorage.removeItem('idTransaccion')
  localStorage.removeItem('transaccion')
}

const generateEmailTemplateClient = (data) => {
  const saludo = "¡Hola! Gracias por confiar en Nativo503";
  const despedida = "¡Que tengas un excelente día!";
  
  const emailTemplate = `
    ${saludo}

    ${data.tripId.destTitle}
    Viajero:        ${data.fullName}
    Participantes:  ${data.customersQty} Adultos (Edad 12-99)
    Fecha:          ${data.reservationDate}
    Idioma:         ${data.preferedLanguage}
    Direccion:      ${data.pickupAddress}
    Total:          $${data.total}
    
    Nuestro numero de Contacto: +50378873305
    
    ${despedida}
  `;
  
  return emailTemplate;
};

const generateEmailTemplateProvider = (data) => {
  const saludo = "¡Hola! Han reservado el siguiente trip";
  const despedida = "¡Que tengas un excelente día!";
  
  const emailTemplate = `
    ${saludo}

    ${data.tripId.destTitle}
    Trip:           ${data.tripId.destTitle}
    Viajero:        ${data.fullName}
    Participantes:  ${data.customersQty} Adultos (Edad 12-99)
    Fecha:          ${data.reservationDate}
    Idioma:         ${data.preferedLanguage}
    Telefono:       ${data.phoneNumber}
    Direccion:      ${data.pickupAddress}
    Pasaporte:      ${data.idNumber}
    Nacionalidad:   ${data.originNation}
    Total:          $${data.total}
    Autorizacion:   ${data.NumeroAutorizacion}
    Estado:         ${data.isPayed == "00" ? "Autorizado" : "Denegado"}
    
    Nuestro numero de Contacto: +50378873305
    
    ${despedida}
  `;
  
  return emailTemplate;
}; 

const generateEmailTemplateClientCar = (data) => {
  const saludo = "¡Hola! Gracias por confiar en Nativo503";
  const despedida = "¡Que tengas un excelente día!";
  
  const emailTemplate = `
    ${saludo}

    ${data.rentACarId.brand} ${data.rentACarId.model} ${data.rentACarId.year}
    Nombre:        ${data.fullname}
    Fecha Inicio:   ${data.startDate}
    Fecha Fin:      ${data.endDate}
    Total:          $${data.totalCost}
    
    Nuestro numero de Contacto: +50378873305
    
    ${despedida}
  `;
  
  return emailTemplate;
};

const generateEmailTemplateProviderCar = (data) => {
  const saludo = "¡Hola! Han reservado el siguiente trip";
  const despedida = "¡Que tengas un excelente día!";
  
  const emailTemplate = `
    ${saludo}

    ${data.rentACarId.brand} ${data.rentACarId.model} ${data.rentACarId.year}
    Nombre:        ${data.fullname}
    Fecha Inicio:   ${data.startDate}
    Fecha Fin:      ${data.endDate}
    Telefono:       ${data.phone}
    Pasaporte:      ${data.idNumber}
    Total:          $${data.totalCost}
    Autorizacion:   ${data.NumeroAutorizacion}
    Estado:         ${data.isPayed == "00" ? "Autorizado" : "Denegado"}
    
    Nuestro numero de Contacto: +50378873305
    
    ${despedida}
  `;
  
  return emailTemplate;
}; 

const sendEmailClient = async (data, msj) =>{
  //setLoading(true);
  const serviceId = "service_rdexzsm";
  const templateId = "template_yrrxzlv";
  try {
    await emailjs.send(serviceId, templateId, {
     to_name: data.fullname,
     recipient: data.email,
     mensaje: msj
    });
    //setLoading(false);
    //alert(t("mailSuccess"),"success");
  } catch (error) {
    console.log(error);
    show_alerta(error, "error")
    //setLoading(false);
  } finally {
    //clearValues();
  }
}



  const sendEmail = async (msj) =>{
      setLoading(true);
      const serviceId = "service_rdexzsm";
      const templateId = "template_yrrxzlv";
      try {
        await emailjs.send(serviceId, templateId, {
         to_name: "Nativo503",
         recipient: "nativo503sv@gmail.com",
         mensaje: msj
        });
        setLoading(false);
        //alert(t("mailSuccess"),"success");
      } catch (error) {
        console.log(error);
        show_alerta(error, "error")
        setLoading(false);
      } finally {
        clearValues();
      }
    }

    const clearValues = () => {
      setState({
        fullname: '',
        email: '',
        phone: '',
        openField: ''
      });
    }
    
    const validar = () =>{
      if (!state.fullname || !state.email || !state.phone || !state.openField) {
        show_alerta(t("validationFields"), "error");
        return;
      }
      sendEmail(generateEmailTemplate())
    }

    const generateEmailTemplate = () => {
      const { fullname, email, phone, openField } = state;
      const saludo = "¡Hola! Una persona quiere conocer tus servicios";
      const despedida = "¡Que tengas un excelente día!";
      
      const emailTemplate = `
        ${saludo}

        Información del Contacto:
        Nombre:    ${fullname}
        Email:     ${email}
        Teléfono:  ${phone}
        Consulta:  ${openField}
        
        ${despedida}
      `;
      
      return emailTemplate;
    };


  const { t } = useTranslation();

  return (
    <section className='home'>
      <div className="overlay"></div>
      <img src={imagen} />

      <div className="homeContent container">
        <div className="textDiv">
          <span data-aos='fade-up' className="smallText">
           {t("welcome")}
          </span>

          <h1 data-aos='fade-up' className="homeTitle">
            {t("discover")}
          </h1>
        </div>

        <div data-aos='fade-up' className="cardDiv grid">
          <div className="destinationInput">
            <label htmlFor='fullname'>{t("labelName")}</label>
            <div className="input flex">
              <input type="text" name='fullname' id='fullname' value={state.fullname} placeholder={t("placeholderName")} onChange={(e) => handleChange(e)} required />
            </div>
          </div>
          <div className="emailInput">
            <label htmlFor='email'>{t("labelEmail")}</label>
            <div className="input flex">
              <input type="text" name='email' id='email' value={state.email} placeholder={t("placeholderEmail")} onChange={(e) => handleChange(e)} required/>
            </div>
          </div>
          <div className="phoneInput">
            <div className="label_total flex">
              <label htmlFor='phone'>{t("labelPhone")} </label>
            </div>
            <div className="input flex">
              <input type="text" name='phone' id='phone' value={state.phone} placeholder={t("placeholderPhone")}  onChange={(e) => handleChange(e)} required/>
            </div>
          </div>

          <div className="openField">
            <label htmlFor='openField'>{t("labelOpen")}</label>
            <div className="input flex">
              <input type="text" name='openField' id='openfield' value={state.openField} placeholder={t("placeholderOpen")} onChange={(e) => handleChange(e)} required/>
            </div>
          </div>

          <div className="searchOptions flex" onClick={() => validar()}>
            <RiBillFill className='icon'/>
            <span>{t("quote")}</span>
          </div>
        </div>


        <div data-aos='fade-up' className="homeFooterIcons flex">
          <div className="rightIcons">
            <a href='https://www.instagram.com/nativo503_?igsh=MXkxdjgwc3RweTJiZA=='><FaInstagram className='icon'/></a>
            <a aria-label="Chat on WhatsApp" href="https://wa.me/50378873305"><FaWhatsapp className='icon'/></a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home