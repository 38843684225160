import React, { useState } from 'react'
import '../App.css'
import Navbar from '../Components/Navbar/Navbar'
import Home from '../Components/Home/Home'
import Main from '../Components/Main/Main'
import Footer from '../Components/Footer/Footer'
import Experiences from '../Components/Experiences/Experiences'

function HomePage() {
  const [searchTrip, setSearchTrip] = useState('')

  const getSearch = (data) =>{
    console.log(data)
    setup()
    setSearchTrip(data)
  }

  function setup(){
    window.scrollTo(0,0)
   }
  return (
    <>
    <Navbar/>
    <Home search={getSearch}/>
    <Main search={searchTrip}/>
    <Experiences />
    <Footer/>
    </>
  )
}

export default HomePage