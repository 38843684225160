import React, {useState, useEffect} from 'react'
import './rentacarcontent.css'
import { useNavigate } from 'react-router-dom';
import { show_alerta } from "../../../Utils/functions"
import emailjs from "@emailjs/browser";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function RentaCarContent({carData}) {

    //This is for inputs
    const [state, setState] = useState({});

    const [email, setEmail] = useState("");

    //const [nationality, setNationality] = useState('');


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
   
    const [excludedDateRanges, setExcludedDateRanges] = useState([]);

    const calculateDaysInRange = () => {
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffTime = Math.abs(end - start);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        }
        return 0;
    }

    const navigate = useNavigate();
    const { t } = useTranslation();

    const baseUrl ='https://nativo503-api.onrender.com/api'
    const serfinsaTest = 'https://testcheckout.redserfinsa.com:8087'
    const serfinsaProd = 'https://www.serfinsacheckout.com/'

    const token1 = 'a4acd9cc-e396-4926-a72d-4cbf5bfcc118'
    const tokenCar = 'e7a6ed27-6977-4bec-b4c4-27b907598efe'

    /*const getData = (data) =>{
        //console.log(data)
        setNationality(data);
    }*/

    const redirect = async(idTransaction) =>{
      var totalSum = calculateDaysInRange() * carData.pricePerDay
      console.log(idTransaction)

      const parametros = {
        TokeyComercio: tokenCar,
        IdTransaccionCliente: idTransaction,
        Monto: totalSum,
        ConceptoPago: "Renta de Vehiculo "+ carData.brand +" "+ carData.model +" "+ carData.year,
        Adicionales: "Cobro por renta de Vehiculo",
        UrlOrigin: "http://nativo503sv.com/detailCar",
        UrlRedirect: `http://nativo503sv.com/`,
      }

     
      var header ={'Content-Type': 'application/json'}
      await axios({method: 'POST',url: serfinsaProd+'/api/PayApi/TokeyTran',headers: header, data: parametros})
              .then(function(response){
                console.log(response.data)
                localStorage.setItem("idTransaccion", idTransaction)
                localStorage.setItem("transaccion", "rentaCar")
                const paymentWindow = window.open(`${serfinsaProd}/${response.data.Datos.UrlPost}`);
                if (!paymentWindow) {
                  show_alerta("No se pudo abrir la ventana de pago", 'error');
              } else {
                  // Mostrar un mensaje al usuario para que cierre manualmente la ventana
                  alert("Se ha abierto la ventana de pago. Por favor, cierre esta ventana manualmente después de completar la transacción.");
              }
              
                //window.open(`${serfinsaProd}/${response.data.Datos.UrlPost}`);
              })
              .catch(function(error){
                show_alerta(error,'error')
                console.log(error)
              })
    }


    const handleBook = async() =>{
        
        if(state.name == null || state.phone == null || state.idNum == null || email == ''){
          show_alerta(t("validationFields"), "error")
        }else{
          reservation()
        }

    }

    const reservation = async() => {
      var totalSum = calculateDaysInRange() * carData.pricePerDay

        //TODO: Cobro por API..

        const parametros = {
          rentACarId: carData._id,
          fullname: state.name,
          startDate: startDate,
          endDate: endDate,
          totalCost: totalSum,
          idNum: state.idNum,
          email: email,
          phone: state.phone,
          isPayed: false
        }

        var header ={'Content-Type': 'application/json'}

        await axios({method: 'POST',url: baseUrl+'/rentacars/'+carData._id+"/reserve",headers: header, data: parametros})
              .then(function(response){
                //console.log(response.data.data)
                show_alerta(t("alertSuccess"), 'success')
                redirect(response.data.data._id)
              })
              .catch(function(error){
                show_alerta(error,'error')
                console.log(error)
              })
    }

    const returnToHome = () => {
      navigate('/')
      console.log("Pasaste al detalle")
    }

    const handleChange = (e) => {
      setState({
        ...state,
        [e.target.name]: e.target.value
      });
    };

    useEffect(()=>{
      setup()
    },[])

    const setup =() =>{
      emailjs.init("f2q_iROJ-VeakYRWT");
      getDates()
    }

    const getDates = async() =>{
      const id = carData._id
      axios.get(baseUrl+'/rentacars/'+id+'/reservations')
        .then(response => {
          // handle success
          //console.log(response.data.reservations)
           // Procesa los rangos de fechas para crear un array de fechas individuales
        const dates = response.data.reservations.flatMap(range => {
          const startDate = new Date(range.startDate);
          const endDate = new Date(range.endDate);
          const datesInRange = [];
          for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            datesInRange.push(new Date(date));
          }
          return datesInRange;
        });
        setExcludedDateRanges(dates); // Actualiza el estado con las fechas desde la API
      
        })
        .catch(error => {
          // handle error
          console.log(error);
        });
    }

    const generateEmailTemplateClient = () => {
      var totalSum = calculateDaysInRange() * carData.pricePerDay
      const { name, phone, idNum  } = state;
      const saludo = "¡Hola! Gracias por confiar en Nativo503";
      const despedida = "¡Que tengas un excelente día!";
      
      const emailTemplate = `
        ${saludo}

        Detalle de tu renta de vehiculo
        ${carData.brand} ${carData.model} ${carData.year}
        Encargado:      ${name}
        Fecha Inicio:   ${startDate}
        Fecha Fin:      ${endDate}
        Total:          $${totalSum}
        
        
        Nuestro numero de Contacto: +50378873305
        
        ${despedida}
      `;
      
      return emailTemplate;
    };
    
    const generateEmailTemplateProvider = () => {
      var totalSum = calculateDaysInRange() * carData.pricePerDay
      const { name, phone, idNum  } = state;
      const saludo = "¡Hola! Han reservado el siguiente vehiculo";
      const despedida = "¡Que tengas un excelente día!";
      
      const emailTemplate = `
        ${saludo}

        ${carData.brand} ${carData.model} ${carData.year}
        Vehiculo:       ${carData.brand} ${carData.model} ${carData.year}
        Encargado:      ${name}
        Fecha Inicio:   ${startDate}
        Fecha Fin:      ${endDate}
        Total:          $${totalSum}
        
        Nuestro numero de Contacto: +50378873305
        
        ${despedida}
      `;
      
      return emailTemplate;
    }; 

    const sendEmail = async (msj) =>{
      //setLoading(true);
      const serviceId = "service_rdexzsm";
      const templateId = "template_yrrxzlv";
      try {
        await emailjs.send(serviceId, templateId, {
         to_name: state.fullname,
         recipient: email,
         mensaje: msj
        });
        //setLoading(false);
        alert(t("mailSuccess"),"success");
      } catch (error) {
        console.log(error);
        show_alerta(error, "error")
        //setLoading(false);
      } finally {
        //clearValues();
      }
    }

    const sendEmailProvider = async (msj) =>{
      //setLoading(true);
      const serviceId = "service_rdexzsm";
      const templateId = "template_yrrxzlv";
      try {
        await emailjs.send(serviceId, templateId, {
         to_name: "Nativo503",
         recipient: "nativo503sv@gmail.com",
         mensaje: msj
        });
        //setLoading(false);
        alert(t("mailSuccess"),"success");
      } catch (error) {
        console.log(error);
        show_alerta(error, "error")
        //setLoading(false);
      } finally {
        //clearValues();
      }
    }

  return (
    <div className='singleDestinationRentAcar container'>
        <h1>{t("formTitle")}</h1>
        <div data-aos='fade-up' className="cardDiv grid">
          
                    <div className="destinationInput">
                        <label htmlFor='name'>{t("labelName")} </label>
                        <div className="input flex">
                          <input type="text" name="name" placeholder={t("placeholderName")} onChange={(e) => handleChange(e)} required />
                        </div>
                      </div>

                      <div className="email">
                        <label htmlFor='email'>{t("labelEmail")} </label>
                        <div className="input flex">
                          <input type="text" name='email' placeholder={t("placeholderEmail")} onChange={(e) => setEmail(e.target.value)} required/>
                        </div>
                      </div>

                      <div className="phoneInput">
                        <label htmlFor='phone'>{t("labelPhone")} </label>
                        <div className="input flex">
                          <input type="text" name='phone' placeholder={t("placeholderPhone")} onChange={(e) => handleChange(e)} required/>
                        </div>
                      </div>

                      <div className="idInput">
                        <label htmlFor='passport'>{t("labelPassport")} </label>
                        <div className="input flex">
                          <input type="text" name='idNum' placeholder={t("placeholderPassport")}  onChange={(e) => handleChange(e)} required/>
                        </div>
                      </div>

                     

                      <div className="dateInput">
                        <label htmlFor='date'>{t("labelDate")}</label>
                        <div className="input date-picker flex">
                        <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            minDate={new Date()}
                            excludeDates={excludedDateRanges}
                            selectsDisabledDaysInRange
                            placeholderText={t("placeholderStartDate")}
                            className="date-picker-input"
                        />
                        <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            excludeDates={excludedDateRanges}
                            selectsDisabledDaysInRange
                            placeholderText={t("placeholderEndDate")}
                            className="date-picker-input"
                        />
                        </div>
                      </div>

                      
      
                      <button className='btn' onClick={handleBook}>
                        {t("btnPago")}
                      </button>

                     
                    </div>
    </div>
  )
}

export default RentaCarContent