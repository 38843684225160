import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import HomePage from './Pages/HomePage'
import Detail from './Pages/Detail'
import About from './Pages/About'
import ConfirmationScreen from './Pages/ConfirmationScreen'
import RentACarDetail from './Pages/RentAcarDetail'
import TravelExperiencePage from './Pages/TravelExperiencePage'
import Reviews from './Pages/Reviews'
import RentACar from './Pages/RentACar'
const App = () => {
  return (
   <div>
   <Router>
    <Routes>
      <Route path='/' element={<HomePage/>} />
      <Route path='/detail' element={<Detail/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/confirmacion' element={<ConfirmationScreen/>} />
      <Route path='/travelExp' element={<TravelExperiencePage/>} />
      <Route path='/reviews' element={<Reviews />} />
      <Route path='/rentacar' element={<RentACar/>}/>
      <Route path='/detailCar' element={<RentACarDetail/>}/>
    </Routes>
   </Router>
   </div>
  )
}

export default App