import React, {useState, useEffect} from 'react'
import { MdPrecisionManufacturing } from "react-icons/md";
import ReactStars from 'react-stars'
import { useTranslation } from 'react-i18next';

import './detailRentACar.css'

function DetailRentACar( {carData}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const nextSlide = () => {
        setActiveIndex((prevIndex) =>
        prevIndex === carData.images.length - 1 ? 0 : prevIndex + 1
        );
    };
    const prevSlide = () => {
        setActiveIndex((prevIndex) =>
        prevIndex === 0 ? carData.images.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
      
    }, []);

    const { t } = useTranslation();

    const { i18n } = useTranslation();

  return (
    <div className="row container">
        <div className="carouselRAC">
        <button onClick={prevSlide} className="carouselRAC__btn carouselRAC__btn--prev">
            &lt;
        </button>
        <img
            src={carData.images[activeIndex]}
            alt={`Slide ${activeIndex}`}
            className="carouselRAC__img"
        />
        <button onClick={nextSlide} className="carouselRAC__btn carouselRAC__btn--next">
            &gt;
        </button>
        </div>

        <div className='itemDataRac'>
            <div data-aos='fade-up' key={carData.id} className="singleDestination">
                  <div className="cardInfoRAC">
                    <h4 className="destTitleRAC">
                     {`${carData.brand} ${carData.model}`}
                    </h4>
                    <span className="continentRAC flex">
                        <MdPrecisionManufacturing className='icon'/>
                      <span className="name">{carData.year}</span>
                    </span>
                    <div className="feesRAC flex">
                      <div className="gradeRAC">
                        <span>{t("pricePerDay")}</span>
                      </div>
                      <div className="priceRAC">
                        <h5>${carData.pricePerDay}</h5>
                      </div>
                    </div>  
                    <div className="descRAC">
                        {i18n.language === 'es' ? <p>{carData.description.translations.es}</p> : <p>{carData.description.translations.en}</p>}
                    </div>  
                  </div>
                </div>
        </div>
    </div>
    
  )
}

export default DetailRentACar