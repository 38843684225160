import React, {useState, useEffect} from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import { useLocation } from "react-router-dom";
import MainDetail from '../Components/MainDetail/MainDetail';
import MainDetailContent from '../Components/MainDetailContent/MainDetailContent';
import Comments from '../Components/Comments/Comments';

const Detail = () =>{

  const location = useLocation();

  useEffect(()=>{
    setup()
  },[location])

  function setup(){
   window.scrollTo(0,0)
  }

  return (
    <>
    <Navbar/>
    <MainDetail tripData={location.state.trip}/>
    <MainDetailContent tripData={location.state.trip}/>
    <Comments tripData={location.state.trip} />
    <Footer />
    </>
  )
}

export default Detail