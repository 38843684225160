import React, {useState, useEffect} from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import { useLocation } from "react-router-dom";
import DetailRentACar from '../Components/RentACarComp/DetailRentACar/DetailRentACar';
import RentaCarContent from '../Components/RentACarComp/RentACarContent/RentACarContent';

const RentACarDetail = () =>{

  const location = useLocation();

  useEffect(()=>{
    setup()
  },[location])

  function setup(){
   window.scrollTo(0,0)
  }

  return (
    <>
    <Navbar/>
    <DetailRentACar carData={location.state.car}/>
    <RentaCarContent carData={location.state.car}/>
    <Footer />
    </>
  )
}

export default RentACarDetail