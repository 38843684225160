import React, {useState, useEffect} from 'react'
import { CiCircleMinus } from "react-icons/ci";
import { CiCirclePlus } from "react-icons/ci";

import './mainDetailContent.css'
import { useNavigate } from 'react-router-dom';
import { show_alerta } from "../../Utils/functions"
import emailjs from "@emailjs/browser";
import CountrySelection from '../CountrySelection';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function MainDetailContent({tripData}) {

    //This is for inputs
    const [state, setState] = useState({});

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false)
    
    const [participants, setParticipants] = useState(1);
    const [nationality, setNationality] = useState('');
    const [desease, setDesease] = useState('no');

    const navigate = useNavigate();
    const { t } = useTranslation();

    const baseUrl ='https://nativo503-api.onrender.com/api'
    const serfinsaTest = 'https://testcheckout.redserfinsa.com:8087'
    const serfinsaProd = 'https://www.serfinsacheckout.com/'

    const token1 = 'a4acd9cc-e396-4926-a72d-4cbf5bfcc118'
    const tokenProd='FDFBE16B-CA1B-4C7A-B894-159952EB5C6C'

    const getData = (data) =>{
        //console.log(data)
        setNationality(data);
    }

    const redirect = async(idTransaction) =>{
      var totalSum = participants * tripData.price

      const parametros = {
        TokeyComercio: tokenProd,
        IdTransaccionCliente: idTransaction,
        Monto: totalSum,
        ConceptoPago: "Reserva de Trip "+ tripData.destTitle,
        Adicionales: "Cobro por reservacion",
        UrlOrigin: "https://nativo503sv.com/detail",
        UrlRedirect: `https://nativo503sv.com/`,
      }

      var header ={'Content-Type': 'application/json'}
      await axios({method: 'POST',url: serfinsaProd+'/api/PayApi/TokeyTran',headers: header, data: parametros})
              .then(function(response){
                //console.log(response.data)
                localStorage.setItem("idTransaccion", idTransaction)
                localStorage.setItem("transaccion", "trip")
                const paymentWindow = window.open(`${serfinsaProd}/${response.data.Datos.UrlPost}`);
                if (!paymentWindow) {
                  show_alerta("No se pudo abrir la ventana de pago", 'error');
              } else {
                  // Mostrar un mensaje al usuario para que cierre manualmente la ventana
                  alert("Se ha abierto la ventana de pago. Por favor, cierre esta ventana manualmente después de completar la transacción.");
              }
              
                //window.open(`${serfinsaProd}/${response.data.Datos.UrlPost}`);
              })
              .catch(function(error){
                show_alerta(error,'error')
                console.log(error)
              })
    }



    const handleBook = async() =>{
        
        if(state.name == null || state.phone == null || state.idNum == null || state.address == null || state.date == null || state.language == null || nationality == '' || email == ''){
          show_alerta(t("validationFields"), "error")
        }else{
          reservation()
        }

    }

    const reservation = async() => {
      var totalSum = participants * tripData.price

        //TODO: Cobro por API..

        const parametros = {
          tripId: tripData._id,
          fullName: state.name,
          email: email,
          customersQty: participants,
          total: totalSum,
          phoneNumber: state.phone,
          idNumber: state.idNum,
          pickupAddress: state.address,
          reservationDate: state.date,
          preferedLanguage: state.language,
          originNation: nationality,
          deseaseOrmedicine: desease
        }

        var header ={'Content-Type': 'application/json'}

        await axios({method: 'POST',url: baseUrl+'/postReservation',headers: header, data: parametros})
              .then(function(response){
                //console.log(response.data)
                show_alerta(t("alertSuccess"), 'success')
                redirect(response.data._id)
              })
              .catch(function(error){
                show_alerta(error,'error')
                console.log(error)
              })
    }


    const returnToHome = () => {
      navigate('/')
      console.log("Pasaste al detalle")
    }

    const handleChange = (e) => {
      setState({
        ...state,
        [e.target.name]: e.target.value
      });
    };

    useEffect(()=>{
      setup()
    },[])

    const setup =() =>{
      emailjs.init("f2q_iROJ-VeakYRWT");
    }


  return (
    <div className='singleDestination container'>
        <h1>{t("formTitle")}</h1>
        <div data-aos='fade-up' className="cardDiv grid">
          
                    <div className="destinationInput">
                        <label htmlFor='name'>{t("labelName")} </label>
                        <div className="input flex">
                          <input type="text" name="name" placeholder={t("placeholderName")} onChange={(e) => handleChange(e)} required />
                        </div>
                      </div>

                      <div className="email">
                        <label htmlFor='email'>{t("labelEmail")} </label>
                        <div className="input flex">
                          <input type="text" name='email' placeholder={t("placeholderEmail")} onChange={(e) => setEmail(e.target.value)} required/>
                        </div>
                      </div>

                      <div className="phoneInput">
                        <label htmlFor='phone'>{t("labelPhone")} </label>
                        <div className="input flex">
                          <input type="text" name='phone' placeholder={t("placeholderPhone")} onChange={(e) => handleChange(e)} required/>
                        </div>
                      </div>

                      <div className="idInput">
                        <label htmlFor='passport'>{t("labelPassport")} </label>
                        <div className="input flex">
                          <input type="text" name='idNum' placeholder={t("placeholderPassport")}  onChange={(e) => handleChange(e)} required/>
                        </div>
                      </div>

                      <div className="address">
                        <label htmlFor='address'>{t("labelAddress")} </label>
                        <div className="input flex">
                          <input type="text" name='address' placeholder={t("placeholderAddress")}  onChange={(e) => handleChange(e)} required/>
                        </div>
                      </div>

                      <div className="dateInput">
                        <label htmlFor='date'>{t("labelDate")}</label>
                        <div className="input date-picker flex">
                          <input type="date" name='date' onChange={(e) => handleChange(e)} required/>
                          <div class="placeholder">{t("placeholderCalendar")}</div> 
                        </div>
                      </div>
                      <div className="customerQty">
                        <label htmlFor='price'>{t("labelParticipants")} Total = ${participants * tripData.price}</label>
                        <div className="input flex">
                          <CiCircleMinus className='icon' onClick={() => setParticipants(participants == 1 ? 1: participants - 1)}/>
                          <input type='text' min={1} value={participants}/>
                          <CiCirclePlus className='icon' onClick={() => setParticipants(participants == 15 ? 15: participants + 1)}/>
                        </div>
                      </div>

                      <div className="preferedLanguage">
                        <label htmlFor="language">{t("labelLanguage")}</label>
                        <div className="input flex">
                        <select name="language" id="language" onChange={(e) => handleChange(e)} required>
                          <option value="">{t("placeholderLanguage")}</option>
                          <option value="English">{t("english")}</option>
                          <option value="Spanish">{t("spanish")}</option>
                        </select>
                        </div>
                      </div>

                      <div className="Nationality">
                       <CountrySelection selectedNation={getData}/>
                      </div>

                      <div className="medicaments">
                        <label htmlFor='medicaments'>{t("labelDeseaseorMedicine")} </label>
                        <div className="input flex">
                          <input type="text" name='deseaseMed' value={desease} placeholder='Enfermedad o medicamento ...'  onChange={(e) => setDesease(e.target.value)} required/>
                        </div>
                      </div>

                      
      
                      <button className='btn' onClick={handleBook}>
                        {t("btnPago")}
                      </button>

                     
                    </div>
    </div>
  )
}

export default MainDetailContent