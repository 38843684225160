import React, {useEffect, useState} from 'react'
import './footer.css'
import imagenfooter from '../../Assets/DJI_0371.JPG'
import { FiSend } from "react-icons/fi";
import { FaYoutube } from "react-icons/fa";
import { FaTripadvisor } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import logo from '../../Assets/logoPrimary.png'
import { useTranslation } from 'react-i18next';

import Aos from 'aos';
import 'aos/dist/aos.css'
import { useNavigate } from 'react-router-dom';

function Footer() {

      useEffect(()=>{
        initializeAos();
    },[])

    const initializeAos = () =>{
      Aos.init({duration: 2000})
    }

    const { t } = useTranslation();

    const navigate = useNavigate();

    const navigateTo = (destination) =>{
      navigate(destination)
  } 

  return (
    <section className="footer">
      <div className="videoDiv">
        <img src={imagenfooter} />
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          <div data-aos='fade-up' className="text">
            <h2>{t("travelWithUs")}</h2>
          </div>

        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <a className='logo flex' onClick={() => navigateTo('/')}>
                 <img src={logo} className='icon'/>Nativo 503
              </a>
            </div>
            <div data-aos='fade-up' className="footerParagraph">
              {t("footerTxt")}
            </div>

            <div data-aos='fade-up' className="footerSocials flex">
              <FaYoutube className='icon'/>
              <a href='https://www.instagram.com/nativo503_?igsh=MXkxdjgwc3RweTJiZA=='><FaInstagram className='icon'/></a>
            </div>
          </div>

          <div className="footerDiv flex">
            <small>Nativo503 </small>
            <small>COPYRIGHTS RESERVED - 2024</small>
          </div>
        
        </div>

        

        
      </div>
    </section>
  )
}

export default Footer