import React, {useState, useEffect} from 'react'
import { HiOutlineLocationMarker } from "react-icons/hi";
import ReactStars from 'react-stars'
import { useTranslation } from 'react-i18next';
import { FaArrowDown } from "react-icons/fa";

import './mainDetail.css'

function MainDetail( {tripData}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const nextSlide = () => {
        setActiveIndex((prevIndex) =>
        prevIndex === tripData.images.length - 1 ? 0 : prevIndex + 1
        );
    };
    const prevSlide = () => {
        setActiveIndex((prevIndex) =>
        prevIndex === 0 ? tripData.images.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
      
    }, []);

    const { t } = useTranslation();

    const { i18n } = useTranslation();

  return (
    <div className="row container">
        <div className="carousel">
        <button onClick={prevSlide} className="carousel__btn carousel__btn--prev">
            &lt;
        </button>
        <img
            src={tripData.images[activeIndex]}
            alt={`Slide ${activeIndex}`}
            className="carousel__img"
        />
        <button onClick={nextSlide} className="carousel__btn carousel__btn--next">
            &gt;
        </button>
        </div>

        <div className='itemData'>
            <div data-aos='fade-up' key={tripData.id} className="singleDestination">
                  <div className="cardInfo">
                    <h4 className="destTitle">
                      {tripData.destTitle}
                    </h4>
                    <div className="rating">
                      <ReactStars
                      count={5}
                      size={24}
                      color2={'#ffd700'}
                      value={tripData.averageRating}
                      edit={false}
                      />
                    </div>
                    <span className="continent flex">
                      <HiOutlineLocationMarker className='icon'/>
                      <span className="name">{tripData.location}</span>
                    </span>

                    <div className="fees flex">
                      <div className="grade">
                        <span>{tripData.category}</span>
                      </div>
                      <div className="price">
                        <h5>${tripData.price}</h5>
                      </div>
                    </div>

                    <div className="desc">
                        {i18n.language === 'es' ? <p>{tripData.description.translations.es}</p> : <p>{tripData.description.translations.en}</p>}
                    </div>


                    <div className="included">
                      <h3>{t("includes")}</h3>
                        <ul>
                            {i18n.language === 'es' && tripData.include.translations.es.map((incluido) =>{
                                return(
                                    <li key={incluido}>{incluido}</li>
                                )
                            })}
                            {i18n.language === 'en' && tripData.include.translations.en.map((included) =>{
                                return(
                                    <li key={included}>{included}</li>
                                )
                            })}
                            <li>{t("maxParticipants")}: 15</li>
                        </ul>
                    </div>
                    
                    <div>
                      <h3>{t("reservaAqui")}</h3>
                      <FaArrowDown className='icon'/>
                    </div>
                    

                  </div>
                </div>
        </div>
    </div>
    
  )
}

export default MainDetail