import React from 'react'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/Navbar/Navbar'
import ReviewGallery from '../Components/ReviewGallery/ReviewGallery'
function Reviews() {
  return (
    <>
    <Navbar/>
    <ReviewGallery />
    <Footer />
    </>
  )
}

export default Reviews