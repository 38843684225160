import React, {useEffect, useState} from 'react'
import './main.css'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import Aos from 'aos';
import 'aos/dist/aos.css'
import ReactStars from 'react-stars';
import { useTranslation } from 'react-i18next';



function Main({search}) {

  const [data, setData] = useState([])
  const baseUrl ='https://nativo503-api.onrender.com/api'

  const navigate = useNavigate();

    useEffect(()=>{
        setup()
    },[])

    const setup = () =>{
      initializeAos();
      getData();

      //TODO: hacer que esto se refresque al buscar...
      //console.log(search)
    }

    const initializeAos = () =>{
      Aos.init({duration: 2000})
    }

    //MARK: - Metodo para traer la lista de los pacientes..
    const getData = async () => {
      await axios.get(baseUrl+'/getAll')
      .then(res => {
         setData(res.data);
        })
        .catch(error => {
          console.log(error)
      })
      
  }

  const openDetail = (trip) => {
    navigate('/detail', { state: { trip }})
    console.log("Pasaste al detalle")
  }

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  return (
    <section className='main container section'>
        <div className='secTitle'>
          <h3 data-aos='fade-right' className="title">
            {t("destiny")}
          </h3>
        </div>

        <div className="secContent grid">
          {
            data.map((trip)=>{
              return(
                <div data-aos='fade-up' key={trip.id} className="singleDestination">
                  <div className="imageDiv">
                    <img src={trip.images[0]} alt={trip.destTitle}/>
                  </div>

                  <div className="cardInfo">
                    <h4 className="destTitle">
                      {trip.destTitle}
                    </h4>
                    <div className="titleWithStars">
                      <ReactStars
                      count={5}
                      size={24}
                      color2={'#ffd700'}
                      value={trip.averageRating}
                      edit={false}
                      />
                      </div>
                      <span className="continent flex">
                        <HiOutlineLocationMarker className='icon'/>
                      <span className="name">{trip.location}</span>
                    </span>

                    <div className="fees flex">
                      <div className="grade">
                        <span>{trip.category}</span>
                      </div>
                      <div className="price">
                        <h5>${trip.price}</h5>
                      </div>
                    </div>

                    <div className="desc">
                      {i18n.language === 'es' ? <p>{trip.description.translations.es}</p> : <p>{trip.description.translations.en}</p>}
                    </div>

                    <button className='btn flex' onClick={() => openDetail(trip)}>
                      {t("details")} <HiOutlineClipboardCheck className='icon' />
                    </button>

                  </div>
                </div>
              )
            })
          }
        </div>
    </section>
  )
}

export default Main