// Gallery.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './reviewGallery.css';
import { useTranslation } from 'react-i18next';
import ReactStars from 'react-stars'
import { IoPersonCircleSharp } from "react-icons/io5";

const ReviewGallery = () => {
  const [reviews, setReviews] = useState([]);

  const baseUrl ='https://nativo503-api.onrender.com/api'

  const { t } = useTranslation();

  useEffect(() => {
    axios.get(baseUrl+"/random-reviews")
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => {
        console.error('Error fetching reviews:', error);
      });
  }, []);

  return (
    <div className='row '>
    <div className='singleDestination container'>
    <h1>{t("reviewsTitle")}</h1>
        <div className='gallery'>
    {reviews?.map((resena) => {
        return(
          <div className='cardDiv' key={resena._id} data-aos='fade-up'>
            
            <h3><IoPersonCircleSharp className='personIcon' />{resena.review.name}</h3> 
            <ReactStars value={resena.review.stars}/>
            <p>{resena.review.commentary}</p>
            <h5>{resena.destTitle}</h5>
            </div>
        
        )
      })}
      </div>
    </div>
    </div>
  );
};

export default ReviewGallery;
