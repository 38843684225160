import React, { useState, useEffect } from 'react'
import './experiences.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from "axios";
import { useTranslation } from 'react-i18next';

function Experiences() {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
      const [activeIndex, setActiveIndex] = useState(0);
      const [images, setImages] = useState([])
      const baseUrl ='https://nativo503-api.onrender.com/api'

      const { t } = useTranslation();

      //MARK: - Metodo para traer la lista de los exp..
    const getExp = async () => {
        await axios.get(baseUrl+'/getExperiences')
        .then(res => {
            //console.log(res)
           setImages(res.data);
          })
          .catch(error => {
            console.log(error)
        })
        
    }

  useEffect(() => {
    getExp();
  }, []);


  return (
    <div className='container flex about'>
        
        <div className="carouselAbout">
        <div className='secTitle'>
          <h3 data-aos='fade-right' className="title">
                {t("experiencesTitle")}
          </h3>
        </div>
        <Carousel responsive={responsive} showDots={true} containerClass="carousel-container">
            {images.map((imagen) => {
            return(
            <img
                key={imagen._id}
                src={imagen.url}
                alt={`Slide ${activeIndex}`}
                className="carousel__img"
                />
                )
                })}
          </Carousel>

        </div>
    </div>
  )
}

export default Experiences