import React from 'react'
import Experiences from '../Components/Experiences/Experiences'
import Footer from '../Components/Footer/Footer'
import Navbar from '../Components/Navbar/Navbar'


function TravelExperiencePage() {
  return (
    <>
    <Navbar/>
    <Experiences />
    <Footer />
    </>
  )
}

export default TravelExperiencePage