import React, { useState } from 'react'
import './navbar.css'
import { MdOutlineTravelExplore } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from '../../Assets/logoPrimary.png'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Navbar() {
    //MARK: Properties
    const [active, setActive] = useState('navBar')
    const [menuOpen, setMenuOpen] = useState(false)
    const [dropActive, setDropActive] = useState('dropboxMenu')

    const navigate = useNavigate();

    //Function to toggle navBar
    const showNav = () => {
        setActive('navBar activeNavbar')
    }

    const closeNav = () => {
        setActive('navBar')
    }

     //Function to toggle navBar
     const showDropNav = () => {
        setDropActive('dropboxMenu active')
    }

    const closeDropNav = () => {
        setDropActive('dropboxMenu')
    }


    const { t } = useTranslation();
    
    const { i18n } = useTranslation();

    function changeLanguage(e) {
      i18n.changeLanguage(e.target.value);
    }

    const navigateTo = (destination) =>{
        navigate(destination)
    } 

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <section className='navBarSection'>
        <header className="header flex">
            <div className="logoDiv">
                <a href="/"  className="logo flex">
                    <h1> <img src={logo} className='icon'/>Nativo503</h1>
                </a>
            </div>

            <div className={active}>
                <ul className="navLists flex">
                    <li className="navItem">
                        <a  className="navLink" onClick={() => navigateTo('/')}>
                           {t("home")}
                        </a>
                    </li>

                    <li className="navItem">
                        <a  className="navLink" onClick={() => navigateTo('/about')}>
                            {t("about")}
                        </a>
                    </li>
                    <li className="navItem">
                        <a  className="navLink" onClick={() => navigateTo('/rentacar')}>
                           Rent A Car
                        </a>
                    </li>
                    <li className="navItem"> 
                        <a  className="navLink" onClick={() => navigateTo('/reviews')} >
                        {t("reviews")}
                        </a>
                    </li>
                    <li className="navItem">
                        <a  className="navLink" onClick={() => navigateTo('/travelExp')}>
                        {t("travelExperience")}
                        </a>
                    </li>
                    <li className="navItem">
                        <a  className="navLink" >
                        Shuttle en C.A
                        </a>
                    </li>
                  
                  
                    <li>
                        <div className='select-input'>
                        <select value={i18n.language}  onChange={changeLanguage}>
                            <option value='es'>{t("spanish")}</option>
                            <option value='en'>{t("english")}</option>
                        </select>
                        </div>
                    </li>
                   

                </ul>

                <div onClick={closeNav} className="closeNavbar">
                    <IoMdCloseCircle  className='icon'/>
                </div>
            </div>

            <div onClick={showNav} className="toggleNavbar">
                <GiHamburgerMenu className='icon'/>
            </div>
        </header>
    </section>
  )
}

export default Navbar