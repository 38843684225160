import React, {useEffect, useState} from 'react'
import './mainrentacar.css'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { MdPrecisionManufacturing } from "react-icons/md";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import Aos from 'aos';
import 'aos/dist/aos.css'
import ReactStars from 'react-stars';
import { useTranslation } from 'react-i18next';



function MainRentAcar() {

  const [data, setData] = useState([])
  const baseUrl ='https://nativo503-api.onrender.com/api'

  const navigate = useNavigate();

    useEffect(()=>{
        setup()
    },[])

    const setup = () =>{
      initializeAos();
      getData();

      //TODO: hacer que esto se refresque al buscar...
      //console.log(search)
    }

    const initializeAos = () =>{
      Aos.init({duration: 2000})
    }

    //MARK: - Metodo para traer la lista de los pacientes..
    const getData = async () => {
      await axios.get(baseUrl+'/rentacars')
      .then(res => {
         setData(res.data);
        })
        .catch(error => {
          console.log(error)
      })
      
  }

  const openDetail = (car) => {
    navigate('/detailCar', { state: { car }})
    console.log("Pasaste al detalle")
  }

  const { t } = useTranslation();
  const { i18n } = useTranslation();


  return (
    <section className='mainCar container section'>
        <div className='secTitleCar'>
          <h3 data-aos='fade-right' className="titleCar">
            {t("rentACarTitle")}
          </h3>
        </div>

        <div className="secContentCar grid">
          {
            data.map((car)=>{
              return(
                <div data-aos='fade-up' key={car.id} className="singleDestinationCar">
                  <div className="imageDivCar">
                    <img src={car.images[0]} alt={car.brand}/>
                  </div>

                  <div className="cardInfoCar">
                    <h4 className="destTitleCar">
                      {`${car.brand} ${car.model}`}
                    </h4>
                    <span className="continentCar flex">
                        <MdPrecisionManufacturing className='icon'/>
                      <span className="name">{car.year}</span>
                    </span>

                    <div className="feesCar flex">
                      <div className="gradeCar">
                        <span>{t("pricePerDay")}</span>
                      </div>
                      <div className="priceCar">
                        <h5>${car.pricePerDay}</h5>
                      </div>
                    </div>

                    <div className="descCar">
                      {i18n.language === 'es' ? <p>{car.description.translations.es}</p> : <p>{car.description.translations.en}</p>}
                    </div>

                    <button className='btn flex' onClick={() => openDetail(car)}>
                      {t("details")} <HiOutlineClipboardCheck className='icon' />
                    </button>

                  </div>
                </div>
              )
            })
          }
        </div>
    </section>
  )
}

export default MainRentAcar