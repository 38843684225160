import React, { useState } from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import '../App.css'
import Aos from 'aos';
import 'aos/dist/aos.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image1 from '../Assets/AboutUs/Image1.jpeg' 
import Image3 from '../Assets/AboutUs/Image3.jpeg' 
import Image4 from '../Assets/AboutUs/Image4.jpeg' 
import Image5 from '../Assets/AboutUs/Image5.jpeg' 
import Image6 from '../Assets/AboutUs/Image6.jpeg'

import { useTranslation } from 'react-i18next';


function About() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const imagenes = [
    Image1,
    Image3, 
    Image4, 
    Image5, 
    Image6
  ]
  const [activeIndex, setActiveIndex] = useState(0);

  const { t } = useTranslation();

  return (
    <>
    {
      //TODO: Agregar como si fuera un carousel, cada uno de los "paquetes."
    }
    <Navbar />
    <div className='container flex about'>
      <div className='secTitle'>
          <h3 data-aos='fade-right' className="title">
            {t("missionTitle")}
          </h3>
        </div>
        <p>{t("missionContent")}</p>

        <div className='secTitle'>
          <h3 data-aos='fade-right' className="title">
            {t("visionTitle")}
          </h3>
        </div>
        <p>{t("visionContent")}</p>
      <div className="carouselAbout">
      <Carousel responsive={responsive} showDots={true} containerClass="carousel-container">
        {imagenes.map((imagen) => {
          return(
          <img
              key={imagen}
              src={imagen}
              alt={`Slide ${activeIndex}`}
              className="carousel__img"
          />
          )
        })}
          </Carousel>

        </div>
    </div>
    <Footer />
    </>
  )
}

export default About